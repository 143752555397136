import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const ServicesContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`;

const Title = styled.h1`
  color: var(--primary-color);
  margin-bottom: 1rem;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
`;

const FilterButton = styled.button`
  padding: 0.5rem 1rem;
  border: 2px solid var(--primary-color);
  border-radius: var(--border-radius);
  background-color: ${props => props.active ? 'var(--primary-color)' : 'transparent'};
  color: ${props => props.active ? 'white' : 'var(--primary-color)'};
  cursor: pointer;
  transition: var(--transition);

  &:hover {
    background-color: var(--primary-color);
    color: white;
  }
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const ServiceCard = styled(motion.div)`
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  transition: var(--transition);

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ServiceTitle = styled.h3`
  color: var(--primary-color);
  margin-bottom: 1rem;
`;

const ServiceDescription = styled.p`
  color: var(--text-light);
  margin-bottom: 1rem;
`;

const ServicePrice = styled.div`
  font-weight: 600;
  color: var(--text-color);
`;

const ServiceBenefits = styled.ul`
  margin: 1rem 0;
  padding-left: 1.5rem;
`;

const ServiceBenefit = styled.li`
  color: var(--text-light);
  margin-bottom: 0.5rem;
`;

// Données des services (à déplacer dans un fichier séparé plus tard)
const services = [
  {
    id: 1,
    title: "Traitement Général",
    description: "Une séance complète pour évaluer et traiter l'ensemble du corps.",
    price: '65€',
    benefits: [
      'Bilan complet',
      'Traitement personnalisé',
      'Conseils préventifs'
    ],
    categories: ['general', 'prevention']
  },
  {
    id: 2,
    title: "Traitement Scoliose",
    description: "Prise en charge spécifique des problèmes de scoliose.",
    price: '70€',
    benefits: [
      'Évaluation posturale',
      'Techniques adaptées',
      'Suivi régulier'
    ],
    categories: ['scoliose', 'posture']
  },
  {
    id: 3,
    title: "Ostéopathie Sportive",
    description: "Traitement adapté aux sportifs et à leurs besoins spécifiques.",
    price: '75€',
    benefits: [
      'Prévention des blessures',
      'Récupération optimale',
      'Performance améliorée'
    ],
    categories: ['sport', 'prevention']
  },
  {
    id: 4,
    title: "Traitement Migraines",
    description: "Soulagement des maux de tête et migraines chroniques.",
    price: '65€',
    benefits: [
      'Diagnostic précis',
      'Soulagement durable',
      'Conseils lifestyle'
    ],
    categories: ['migraines', 'douleur']
  }
];

const categories = [
  { id: 'all', label: 'Tous' },
  { id: 'general', label: 'Général' },
  { id: 'sport', label: 'Sport' },
  { id: 'scoliose', label: 'Scoliose' },
  { id: 'migraines', label: 'Migraines' },
  { id: 'prevention', label: 'Prévention' }
];

const Services = () => {
  const [activeCategory, setActiveCategory] = useState('all');

  const filteredServices = services.filter(service => 
    activeCategory === 'all' || service.categories.includes(activeCategory)
  );

  return (
    <ServicesContainer>
      <Header>
        <Title>Nos Services</Title>
        <p>Découvrez notre gamme complète de services ostéopathiques</p>
      </Header>

      <FilterContainer>
        {categories.map(category => (
          <FilterButton
            key={category.id}
            active={activeCategory === category.id}
            onClick={() => setActiveCategory(category.id)}
          >
            {category.label}
          </FilterButton>
        ))}
      </FilterContainer>

      <ServicesGrid>
        {filteredServices.map(service => (
          <ServiceCard
            key={service.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <ServiceTitle>{service.title}</ServiceTitle>
            <ServiceDescription>{service.description}</ServiceDescription>
            <ServiceBenefits>
              {service.benefits.map((benefit, index) => (
                <ServiceBenefit key={index}>{benefit}</ServiceBenefit>
              ))}
            </ServiceBenefits>
            <ServicePrice>Tarif : {service.price}</ServicePrice>
          </ServiceCard>
        ))}
      </ServicesGrid>
    </ServicesContainer>
  );
};

export default Services;
