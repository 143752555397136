import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';

const HeroScene = () => {
  const group = useRef();
  
  // Animation de rotation douce
  useFrame((state) => {
    const t = state.clock.getElapsedTime();
    group.current.rotation.y = Math.sin(t / 2) * 0.1;
  });

  // En attendant un modèle 3D spécifique, nous créons une forme abstraite
  return (
    <group ref={group}>
      <ambientLight intensity={0.5} />
      <directionalLight position={[10, 10, 5]} intensity={1} />
      <mesh position={[0, 0, 0]}>
        <torusKnotGeometry args={[1, 0.3, 128, 16]} />
        <meshStandardMaterial
          color="#4299E1"
          metalness={0.5}
          roughness={0.5}
          emissive="#2C5282"
          emissiveIntensity={0.2}
        />
      </mesh>
      <mesh position={[2, 0, -2]}>
        <sphereGeometry args={[0.5, 32, 32]} />
        <meshStandardMaterial
          color="#90CDF4"
          metalness={0.3}
          roughness={0.7}
        />
      </mesh>
      <mesh position={[-2, 0, -2]}>
        <sphereGeometry args={[0.5, 32, 32]} />
        <meshStandardMaterial
          color="#90CDF4"
          metalness={0.3}
          roughness={0.7}
        />
      </mesh>
    </group>
  );
};

export default HeroScene;
