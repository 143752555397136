import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField, MenuItem, Button } from '@mui/material';
import frLocale from 'date-fns/locale/fr';

const BookingContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 1rem;
`;

const Title = styled.h1`
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: white;
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const SubmitButton = styled(motion.button)`
  background-color: var(--primary-color);
  color: white;
  padding: 1rem;
  border: none;
  border-radius: var(--border-radius);
  font-size: 1.1rem;
  cursor: pointer;
  transition: var(--transition);

  &:hover {
    background-color: var(--secondary-color);
  }

  &:disabled {
    background-color: var(--text-light);
    cursor: not-allowed;
  }
`;

const ConfirmationModal = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 90%;
  width: 400px;
  text-align: center;
`;

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const services = [
  { id: 'general', label: 'Traitement Général - 65€' },
  { id: 'sport', label: 'Ostéopathie Sportive - 75€' },
  { id: 'scoliose', label: 'Traitement Scoliose - 70€' },
  { id: 'migraines', label: 'Traitement Migraines - 65€' }
];

const Booking = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    service: '',
    date: null,
    time: null,
    notes: ''
  });
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Ici, vous ajouteriez la logique pour envoyer les données à votre backend
    setShowConfirmation(true);
  };

  const handleInputChange = (field) => (event) => {
    setFormData({
      ...formData,
      [field]: event.target.value
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      date
    });
  };

  const handleTimeChange = (time) => {
    setFormData({
      ...formData,
      time
    });
  };

  const isFormValid = () => {
    return (
      formData.name &&
      formData.email &&
      formData.phone &&
      formData.service &&
      formData.date &&
      formData.time
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
      <BookingContainer>
        <Title>Réservation de Consultation</Title>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <TextField
              label="Nom complet"
              value={formData.name}
              onChange={handleInputChange('name')}
              required
            />
          </FormGroup>

          <FormGroup>
            <TextField
              label="Email"
              type="email"
              value={formData.email}
              onChange={handleInputChange('email')}
              required
            />
          </FormGroup>

          <FormGroup>
            <TextField
              label="Téléphone"
              value={formData.phone}
              onChange={handleInputChange('phone')}
              required
            />
          </FormGroup>

          <FormGroup>
            <TextField
              select
              label="Type de consultation"
              value={formData.service}
              onChange={handleInputChange('service')}
              required
            >
              {services.map((service) => (
                <MenuItem key={service.id} value={service.id}>
                  {service.label}
                </MenuItem>
              ))}
            </TextField>
          </FormGroup>

          <FormGroup>
            <DatePicker
              label="Date"
              value={formData.date}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} />}
              minDate={new Date()}
            />
          </FormGroup>

          <FormGroup>
            <TimePicker
              label="Heure"
              value={formData.time}
              onChange={handleTimeChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormGroup>

          <FormGroup>
            <TextField
              label="Notes ou demandes spéciales"
              multiline
              rows={4}
              value={formData.notes}
              onChange={handleInputChange('notes')}
            />
          </FormGroup>

          <SubmitButton
            type="submit"
            disabled={!isFormValid()}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            Confirmer la réservation
          </SubmitButton>
        </Form>

        {showConfirmation && (
          <>
            <Overlay
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
            <ConfirmationModal
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
            >
              <h2>Réservation Confirmée!</h2>
              <p>
                Votre rendez-vous a été enregistré avec succès. Vous recevrez
                bientôt un email de confirmation.
              </p>
              <Button
                variant="contained"
                onClick={() => setShowConfirmation(false)}
                style={{ marginTop: '1rem' }}
              >
                Fermer
              </Button>
            </ConfirmationModal>
          </>
        )}
      </BookingContainer>
    </LocalizationProvider>
  );
};

export default Booking;
