import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { TextField, Button, Snackbar, Alert } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

const ContactContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
`;

const Title = styled.h1`
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
`;

const ContactContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: white;
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ContactInfo = styled.div`
  background-color: var(--primary-color);
  color: white;
  padding: 2rem;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  svg {
    font-size: 1.5rem;
  }
`;

const SubmitButton = styled(motion.button)`
  background-color: var(--primary-color);
  color: white;
  padding: 1rem;
  border: none;
  border-radius: var(--border-radius);
  font-size: 1.1rem;
  cursor: pointer;
  transition: var(--transition);

  &:hover {
    background-color: var(--secondary-color);
  }

  &:disabled {
    background-color: var(--text-light);
    cursor: not-allowed;
  }
`;

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Ici, vous implémenteriez la logique d'envoi d'email
      // Pour l'instant, simulons une réponse réussie
      setSnackbar({
        open: true,
        message: 'Votre message a été envoyé avec succès!',
        severity: 'success'
      });
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Une erreur est survenue. Veuillez réessayer.',
        severity: 'error'
      });
    }
  };

  const handleInputChange = (field) => (event) => {
    setFormData({
      ...formData,
      [field]: event.target.value
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <ContactContainer>
      <Title>Contactez-nous</Title>
      <ContactContent>
        <ContactForm onSubmit={handleSubmit}>
          <TextField
            label="Nom"
            value={formData.name}
            onChange={handleInputChange('name')}
            required
            fullWidth
          />
          <TextField
            label="Email"
            type="email"
            value={formData.email}
            onChange={handleInputChange('email')}
            required
            fullWidth
          />
          <TextField
            label="Message"
            multiline
            rows={6}
            value={formData.message}
            onChange={handleInputChange('message')}
            required
            fullWidth
          />
          <SubmitButton
            type="submit"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            Envoyer le message
          </SubmitButton>
        </ContactForm>

        <ContactInfo>
          <h2>Informations de Contact</h2>
          <InfoItem>
            <LocationOnIcon />
            <div>
              <h3>Adresse</h3>
              <p>123 Rue de l'Ostéopathie<br />75000 Paris</p>
            </div>
          </InfoItem>
          <InfoItem>
            <PhoneIcon />
            <div>
              <h3>Téléphone</h3>
              <p>01 23 45 67 89</p>
            </div>
          </InfoItem>
          <InfoItem>
            <EmailIcon />
            <div>
              <h3>Email</h3>
              <p>contact@socy.fr</p>
            </div>
          </InfoItem>
        </ContactInfo>
      </ContactContent>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </ContactContainer>
  );
};

export default Contact;
