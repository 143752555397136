import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';
import Close from '@mui/icons-material/Close';

const Nav = styled.nav`
  background-color: #1a1a1a;
  padding: 1.2rem 0;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const NavContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1024px) {
    padding: 0 1rem;
  }
`;

const Logo = styled(Link)`
  font-size: 1.8rem;
  font-weight: bold;
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.9;
  }
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const MobileMenu = styled.div`
  display: none;
  
  @media (max-width: 1024px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #1a1a1a;
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    > a {
      padding: 1rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      
      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: ${({ active }) => (active ? '600' : '400')};
  font-size: 1.1rem;
  padding: 0.7rem 1rem;
  border-radius: 4px;
  white-space: nowrap;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  ${({ active }) => active && `
    background-color: rgba(255, 255, 255, 0.15);
  `}

  @media (max-width: 1024px) {
    width: 100%;
    text-align: left;
    padding: 1rem;
  }
`;

const ExternalLink = styled.a`
  color: white;
  text-decoration: none;
  font-weight: 400;
  font-size: 1.1rem;
  padding: 0.7rem 1rem;
  border-radius: 4px;
  white-space: nowrap;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 1024px) {
    width: 100%;
    text-align: left;
    padding: 1rem;
  }
`;

const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: white;
  padding: 0.8rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

function Navigation() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const navItems = [
    { path: '/about', label: 'À Propos' },
    { path: '/services', label: 'Services' },
    { path: '/booking', label: 'Réservation' },
    { path: '/contact', label: 'Contact' },
    { path: '/tools/diagnostic', label: 'Diagnostic' },
    { path: '/tools/exercise', label: 'Exercices' },
    { path: '/tools/posture', label: 'Posture' }
  ];

  return (
    <Nav>
      <NavContainer>
        <Logo to="/">Dr. Béville</Logo>
        
        <NavMenu>
          {navItems.map((item) => (
            <NavLink 
              key={item.path}
              to={item.path}
              active={location.pathname === item.path}
            >
              {item.label}
            </NavLink>
          ))}
          <ExternalLink 
            href="/blog"
          >
            Blog
          </ExternalLink>
        </NavMenu>

        <MenuButton onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <Close /> : <MenuIcon />}
        </MenuButton>

        <MobileMenu isOpen={isOpen}>
          {navItems.map((item) => (
            <NavLink
              key={item.path}
              to={item.path}
              active={location.pathname === item.path}
              onClick={() => setIsOpen(false)}
            >
              {item.label}
            </NavLink>
          ))}
          <ExternalLink 
            href="/blog"
            onClick={() => setIsOpen(false)}
          >
            Blog
          </ExternalLink>
        </MobileMenu>
      </NavContainer>
    </Nav>
  );
}

export default Navigation;
