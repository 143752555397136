import React from 'react';
import styled from 'styled-components';

const TarifsContainer = styled.div`
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-family: 'Playfair Display', serif;
  color: var(--primary-color);
  margin-bottom: 2rem;
  text-align: center;
`;

const PriceCard = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 2rem;
`;

const PriceTitle = styled.h2`
  color: var(--primary-color);
  margin-bottom: 1rem;
`;

const Price = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary-color);
`;

const Description = styled.p`
  color: #666;
  line-height: 1.6;
`;

const Tarifs = () => {
  return (
    <TarifsContainer>
      <Title>Tarifs des Consultations</Title>
      
      <PriceCard>
        <PriceTitle>Consultation Standard</PriceTitle>
        <Price>55€</Price>
        <Description>
          Consultation d'ostéopathie complète d'une durée de 45 minutes à 1 heure.
          Comprend un bilan complet et le traitement adapté à vos besoins.
        </Description>
      </PriceCard>

      <PriceCard>
        <PriceTitle>Consultation Nourrisson (0-1 an)</PriceTitle>
        <Price>55€</Price>
        <Description>
          Consultation spécialisée pour les nourrissons, avec une approche douce
          et adaptée aux tout-petits.
        </Description>
      </PriceCard>

      <Description style={{ textAlign: 'center', marginTop: '2rem' }}>
        Les consultations sont prises en charge par certaines mutuelles.
        N'hésitez pas à vous renseigner auprès de la vôtre.
      </Description>
    </TarifsContainer>
  );
};

export default Tarifs;
