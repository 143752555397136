import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Container, Grid, Typography, Box } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

const FooterContainer = styled.footer`
  background-color: var(--primary-color);
  color: white;
  padding: 3rem 0;
  margin-top: auto;
`;

const FooterLink = styled(Link)`
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ExternalLink = styled.a`
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const IconWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  svg {
    margin-right: 0.5rem;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Contact
            </Typography>
            <IconWrapper>
              <LocationOnIcon />
              <Typography>123 rue de l'Ostéopathie, 75000 Paris</Typography>
            </IconWrapper>
            <IconWrapper>
              <PhoneIcon />
              <Typography>01 23 45 67 89</Typography>
            </IconWrapper>
            <IconWrapper>
              <EmailIcon />
              <Typography>contact@osteopathe-beville.fr</Typography>
            </IconWrapper>
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Navigation
            </Typography>
            <Box display="flex" flexDirection="column">
              <FooterLink to="/">Cabinet</FooterLink>
              <FooterLink to="/tarifs">Tarifs</FooterLink>
              <FooterLink to="/booking">Rendez-vous</FooterLink>
              <ExternalLink href="https://osteopathe-beville.fr/blog">Blog</ExternalLink>
            </Box>
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Horaires
            </Typography>
            <Typography>
              Lundi - Vendredi : 9h - 19h<br />
              Samedi : 9h - 13h<br />
              Dimanche : Fermé
            </Typography>
          </Grid>
        </Grid>
        
        <Box mt={4} textAlign="center">
          <Typography variant="body2">
            &copy; {new Date().getFullYear()} Cabinet d'Ostéopathie Beville. Tous droits réservés.
          </Typography>
        </Box>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
