import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { 
  Typography, 
  Button, 
  Stepper, 
  Step, 
  StepLabel, 
  Card,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Alert,
  Box
} from '@mui/material';

const DiagnosticContainer = styled(motion.div)`
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
`;

const QuestionCard = styled(Card)`
  padding: 2rem;
  margin: 2rem 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const ResultCard = styled(Card)`
  padding: 2rem;
  margin: 2rem 0;
  background-color: #f8f9fa;
`;

const questions = [
  {
    id: 1,
    question: "Où ressentez-vous principalement la douleur ?",
    options: [
      "Cou/Nuque",
      "Dos (haut)",
      "Dos (bas)",
      "Épaules",
      "Hanches",
      "Genoux"
    ]
  },
  {
    id: 2,
    question: "Depuis combien de temps ressentez-vous cette douleur ?",
    options: [
      "Moins d'une semaine",
      "1-4 semaines",
      "1-3 mois",
      "Plus de 3 mois"
    ]
  },
  {
    id: 3,
    question: "Comment décririez-vous la douleur ?",
    options: [
      "Aiguë/Vive",
      "Sourde/Diffuse",
      "Lancinante",
      "Brûlante"
    ]
  },
  {
    id: 4,
    question: "La douleur est-elle liée à une activité particulière ?",
    options: [
      "Travail de bureau",
      "Activité sportive",
      "Accident/Traumatisme",
      "Aucune activité particulière"
    ]
  },
  {
    id: 5,
    question: "À quel moment la douleur est-elle la plus intense ?",
    options: [
      "Au réveil",
      "En fin de journée",
      "Pendant l'activité",
      "Au repos"
    ]
  }
];

const DiagnosticTool = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const [showResult, setShowResult] = useState(false);

  const handleNext = () => {
    if (activeStep < questions.length - 1) {
      setActiveStep(prev => prev + 1);
    } else {
      setShowResult(true);
    }
  };

  const handleBack = () => {
    setActiveStep(prev => prev - 1);
  };

  const handleAnswer = (event) => {
    setAnswers({
      ...answers,
      [activeStep]: event.target.value
    });
  };

  const getRecommendation = () => {
    const recommendations = {
      urgence: [],
      traitement: [],
      prevention: []
    };

    // Analyse des réponses pour générer des recommandations personnalisées
    const douleur = answers[0];
    const duree = answers[1];
    const type = answers[2];
    const cause = answers[3];

    // Recommandations d'urgence
    if (type === "Aiguë/Vive" && duree === "Moins d'une semaine") {
      recommendations.urgence.push(
        "Consultez rapidement un professionnel de santé pour évaluer la gravité de votre situation."
      );
    }

    // Recommandations de traitement
    if (douleur === "Dos (bas)" || douleur === "Dos (haut)") {
      recommendations.traitement.push(
        "Application de chaleur pendant 15-20 minutes",
        "Évitez les mouvements brusques",
        "Consultation ostéopathique recommandée pour évaluation"
      );
    }

    if (cause === "Travail de bureau") {
      recommendations.prevention.push(
        "Adoptez une position ergonomique au bureau",
        "Faites des pauses régulières toutes les heures",
        "Pratiquez des exercices d'étirement simples"
      );
    }

    return recommendations;
  };

  const renderQuestion = () => {
    const currentQuestion = questions[activeStep];
    return (
      <QuestionCard component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <Typography variant="h6" gutterBottom>
          {currentQuestion.question}
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            value={answers[activeStep] || ""}
            onChange={handleAnswer}
          >
            {currentQuestion.options.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option}
                control={<Radio />}
                label={option}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </QuestionCard>
    );
  };

  const renderResult = () => {
    const recommendations = getRecommendation();
    return (
      <ResultCard component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <Typography variant="h5" gutterBottom color="primary">
          Analyse de vos Symptômes
        </Typography>

        {recommendations.urgence.length > 0 && (
          <Box mb={3}>
            <Alert severity="warning">
              <Typography variant="subtitle1" gutterBottom>
                Recommandations Urgentes :
              </Typography>
              {recommendations.urgence.map((rec, index) => (
                <Typography key={index} variant="body2">{rec}</Typography>
              ))}
            </Alert>
          </Box>
        )}

        <Box mb={3}>
          <Typography variant="subtitle1" gutterBottom>
            Recommandations de Traitement :
          </Typography>
          {recommendations.traitement.map((rec, index) => (
            <Typography key={index} variant="body2">• {rec}</Typography>
          ))}
        </Box>

        <Box mb={3}>
          <Typography variant="subtitle1" gutterBottom>
            Prévention :
          </Typography>
          {recommendations.prevention.map((rec, index) => (
            <Typography key={index} variant="body2">• {rec}</Typography>
          ))}
        </Box>

        <Alert severity="info">
          Ces recommandations sont données à titre indicatif. Pour un diagnostic précis, 
          consultez un professionnel de santé.
        </Alert>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setActiveStep(0);
            setAnswers({});
            setShowResult(false);
          }}
          sx={{ mt: 3 }}
        >
          Recommencer le Diagnostic
        </Button>
      </ResultCard>
    );
  };

  return (
    <DiagnosticContainer
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Typography variant="h4" gutterBottom align="center">
        Outil de Diagnostic
      </Typography>
      <Typography variant="body1" paragraph align="center">
        Répondez à ces questions pour obtenir une première évaluation de vos symptômes
      </Typography>

      {!showResult && (
        <>
          <Stepper activeStep={activeStep} alternativeLabel>
            {questions.map((question, index) => (
              <Step key={index}>
                <StepLabel>Question {index + 1}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {renderQuestion()}

          <ButtonGroup>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="outlined"
            >
              Précédent
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={!answers[activeStep]}
            >
              {activeStep === questions.length - 1 ? "Voir les Résultats" : "Suivant"}
            </Button>
          </ButtonGroup>
        </>
      )}

      {showResult && renderResult()}
    </DiagnosticContainer>
  );
};

export default DiagnosticTool;
