import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --primary-color: #2C5282;
    --secondary-color: #4299E1;
    --accent-color: #90CDF4;
    --background-color: #FFFFFF;
    --text-color: #2D3748;
    --text-light: #718096;
    --error-color: #E53E3E;
    --success-color: #48BB78;
    --border-radius: 8px;
    --transition: all 0.3s ease-in-out;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Poppins', sans-serif;
    color: var(--text-color);
    background-color: var(--background-color);
    line-height: 1.6;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }

  a {
    color: var(--primary-color);
    text-decoration: none;
    transition: var(--transition);

    &:hover {
      color: var(--secondary-color);
    }
  }

  button {
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    transition: var(--transition);
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }

  @media (max-width: 768px) {
    html {
      font-size: 14px;
    }
  }
`;

export default GlobalStyle;
