import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Typography, Card, Grid, Avatar, Divider } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import VerifiedIcon from '@mui/icons-material/Verified';

const AboutContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const HeroSection = styled.div`
  text-align: center;
  margin-bottom: 4rem;
`;

const ProfileImage = styled(Avatar)`
  width: 200px !important;
  height: 200px !important;
  margin: 0 auto 2rem !important;
`;

const Section = styled(motion.div)`
  margin-bottom: 3rem;
`;

const StyledCard = styled(Card)`
  padding: 2rem;
  height: 100%;
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: var(--primary-color);
  gap: 0.5rem;
`;

const About = () => {
  return (
    <AboutContainer>
      <HeroSection>
        <ProfileImage
          src="/images/osteopathe.jpg"
          alt="Dr. Beville"
        />
        <Typography variant="h3" component="h1" gutterBottom>
          Dr. Beville
        </Typography>
        <Typography variant="h5" color="textSecondary" gutterBottom>
          Ostéopathe D.O.
        </Typography>
        <Typography variant="body1" paragraph>
          Plus de 10 ans d'expérience en ostéopathie
        </Typography>
      </HeroSection>

      <Section
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography variant="h4" gutterBottom>
          Notre Approche
        </Typography>
        <Typography variant="body1" paragraph>
          En tant qu'ostéopathe, je considère chaque patient dans sa globalité. Mon approche holistique 
          prend en compte non seulement les symptômes physiques, mais aussi le mode de vie, 
          l'environnement et le bien-être général de mes patients.
        </Typography>
      </Section>

      <Grid container spacing={4} component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2 }}>
        <Grid item xs={12} md={4}>
          <StyledCard>
            <IconWrapper>
              <SchoolIcon fontSize="large" />
              <Typography variant="h6">Formation</Typography>
            </IconWrapper>
            <Typography variant="body1" paragraph>
              • Diplôme d'Ostéopathie (D.O.)
            </Typography>
            <Typography variant="body1" paragraph>
              • Formation continue en techniques structurelles
            </Typography>
            <Typography variant="body1">
              • Spécialisation en ostéopathie pédiatrique
            </Typography>
          </StyledCard>
        </Grid>

        <Grid item xs={12} md={4}>
          <StyledCard>
            <IconWrapper>
              <WorkHistoryIcon fontSize="large" />
              <Typography variant="h6">Expérience</Typography>
            </IconWrapper>
            <Typography variant="body1" paragraph>
              • 10+ années de pratique clinique
            </Typography>
            <Typography variant="body1" paragraph>
              • Collaboration avec des athlètes professionnels
            </Typography>
            <Typography variant="body1">
              • Intervention en entreprise pour la prévention
            </Typography>
          </StyledCard>
        </Grid>

        <Grid item xs={12} md={4}>
          <StyledCard>
            <IconWrapper>
              <VerifiedIcon fontSize="large" />
              <Typography variant="h6">Spécialités</Typography>
            </IconWrapper>
            <Typography variant="body1" paragraph>
              • Troubles musculo-squelettiques
            </Typography>
            <Typography variant="body1" paragraph>
              • Ostéopathie du sport
            </Typography>
            <Typography variant="body1">
              • Rééducation posturale
            </Typography>
          </StyledCard>
        </Grid>
      </Grid>

      <Section
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
      >
        <Divider sx={{ margin: "3rem 0" }} />
        <Typography variant="h4" gutterBottom>
          Engagement Professionnel
        </Typography>
        <Typography variant="body1" paragraph>
          Mon engagement envers mes patients va au-delà du simple traitement. Je m'investis dans 
          leur rétablissement complet en proposant des solutions personnalisées et un suivi adapté 
          à chaque situation. La formation continue et la mise à jour régulière de mes connaissances 
          font partie intégrante de ma pratique pour offrir les meilleurs soins possibles.
        </Typography>
      </Section>

      <Section
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.6 }}
      >
        <Typography variant="h4" gutterBottom>
          Cabinet et Équipement
        </Typography>
        <Typography variant="body1" paragraph>
          Le cabinet est équipé de matériel moderne et adapté pour assurer des soins de qualité. 
          L'environnement a été pensé pour votre confort et votre bien-être. Les normes d'hygiène 
          les plus strictes sont respectées pour garantir votre sécurité.
        </Typography>
      </Section>
    </AboutContainer>
  );
};

export default About;
