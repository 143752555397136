import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
  Typography,
  Card,
  Button,
  Stepper,
  Step,
  StepLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Box,
  Alert,
  Grid,
  LinearProgress
} from '@mui/material';
import PostureIcon from '@mui/icons-material/AccessibilityNew';
import ComputerIcon from '@mui/icons-material/Computer';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import NightShelterIcon from '@mui/icons-material/NightShelter';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';

const QuizContainer = styled(motion.div)`
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
`;

const QuestionCard = styled(Card)`
  padding: 2rem;
  margin: 2rem 0;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ResultCard = styled(Card)`
  padding: 2rem;
  margin: 2rem 0;
  background-color: #f8f9fa;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
`;

const ProgressContainer = styled(Box)`
  margin: 2rem 0;
`;

const quizQuestions = [
  {
    id: 1,
    question: "Comment décririez-vous votre posture assise habituelle ?",
    icon: <PostureIcon fontSize="large" />,
    options: [
      "Dos droit, épaules détendues",
      "Légèrement penché en avant",
      "Très penché vers l'écran",
      "Affalé dans la chaise"
    ]
  },
  {
    id: 2,
    question: "Combien d'heures par jour passez-vous devant un écran ?",
    icon: <ComputerIcon fontSize="large" />,
    options: [
      "Moins de 4 heures",
      "Entre 4 et 6 heures",
      "Entre 6 et 8 heures",
      "Plus de 8 heures"
    ]
  },
  {
    id: 3,
    question: "À quelle fréquence faites-vous des pauses pour bouger ?",
    icon: <DirectionsWalkIcon fontSize="large" />,
    options: [
      "Toutes les heures",
      "2-3 fois par jour",
      "Rarement",
      "Jamais"
    ]
  },
  {
    id: 4,
    question: "Pratiquez-vous une activité physique régulière ?",
    icon: <FitnessCenterIcon fontSize="large" />,
    options: [
      "Plus de 3 fois par semaine",
      "1-2 fois par semaine",
      "Occasionnellement",
      "Jamais"
    ]
  },
  {
    id: 5,
    question: "Comment dormez-vous habituellement ?",
    icon: <NightShelterIcon fontSize="large" />,
    options: [
      "Sur le dos avec un bon maintien",
      "Sur le côté avec un oreiller adapté",
      "Sur le ventre",
      "Position changeante"
    ]
  }
];

const PostureQuiz = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const [showResult, setShowResult] = useState(false);

  const handleNext = () => {
    if (activeStep < quizQuestions.length - 1) {
      setActiveStep(prev => prev + 1);
    } else {
      setShowResult(true);
    }
  };

  const handleBack = () => {
    setActiveStep(prev => prev - 1);
  };

  const handleAnswer = (event) => {
    setAnswers({
      ...answers,
      [activeStep]: parseInt(event.target.value)
    });
  };

  const calculateScore = () => {
    let score = 0;
    Object.values(answers).forEach(answer => {
      score += answer;
    });
    return score;
  };

  const getRecommendations = () => {
    const score = calculateScore();
    const recommendations = {
      posture: [],
      exercices: [],
      lifestyle: []
    };

    // Analyse des réponses et recommandations personnalisées
    if (answers[0] > 1) {
      recommendations.posture.push(
        "Ajustez votre position assise : dos droit, épaules détendues, écran à hauteur des yeux"
      );
    }

    if (answers[1] > 1) {
      recommendations.lifestyle.push(
        "Faites des pauses régulières toutes les heures",
        "Utilisez la règle 20-20-20 : toutes les 20 minutes, regardez à 20 pieds pendant 20 secondes"
      );
    }

    if (answers[2] > 1) {
      recommendations.exercices.push(
        "Intégrez des exercices d'étirement dans votre routine quotidienne",
        "Pratiquez des exercices de renforcement musculaire doux"
      );
    }

    if (answers[3] > 1) {
      recommendations.lifestyle.push(
        "Augmentez progressivement votre activité physique",
        "Commencez par 15-30 minutes de marche quotidienne"
      );
    }

    if (answers[4] === 2) {
      recommendations.posture.push(
        "Évitez de dormir sur le ventre",
        "Utilisez un oreiller adapté à votre morphologie"
      );
    }

    return recommendations;
  };

  const renderQuestion = () => {
    const currentQuestion = quizQuestions[activeStep];
    return (
      <QuestionCard component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <IconContainer>
          {currentQuestion.icon}
          <Typography variant="h6">
            {currentQuestion.question}
          </Typography>
        </IconContainer>
        <FormControl component="fieldset">
          <RadioGroup
            value={answers[activeStep] || ""}
            onChange={handleAnswer}
          >
            {currentQuestion.options.map((option, index) => (
              <FormControlLabel
                key={index}
                value={index}
                control={<Radio />}
                label={option}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </QuestionCard>
    );
  };

  const renderResult = () => {
    const recommendations = getRecommendations();
    return (
      <ResultCard component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <Typography variant="h5" gutterBottom color="primary">
          Analyse de votre Posture
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Alert severity="info" sx={{ mb: 3 }}>
              Basé sur vos réponses, voici des recommandations personnalisées pour améliorer votre posture
              et votre bien-être quotidien.
            </Alert>
          </Grid>

          {recommendations.posture.length > 0 && (
            <Grid item xs={12} md={4}>
              <Card sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" gutterBottom>
                  Recommandations Posturales
                </Typography>
                <ul>
                  {recommendations.posture.map((rec, index) => (
                    <li key={index}>
                      <Typography variant="body2">{rec}</Typography>
                    </li>
                  ))}
                </ul>
              </Card>
            </Grid>
          )}

          {recommendations.exercices.length > 0 && (
            <Grid item xs={12} md={4}>
              <Card sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" gutterBottom>
                  Exercices Recommandés
                </Typography>
                <ul>
                  {recommendations.exercices.map((rec, index) => (
                    <li key={index}>
                      <Typography variant="body2">{rec}</Typography>
                    </li>
                  ))}
                </ul>
              </Card>
            </Grid>
          )}

          {recommendations.lifestyle.length > 0 && (
            <Grid item xs={12} md={4}>
              <Card sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" gutterBottom>
                  Conseils Mode de Vie
                </Typography>
                <ul>
                  {recommendations.lifestyle.map((rec, index) => (
                    <li key={index}>
                      <Typography variant="body2">{rec}</Typography>
                    </li>
                  ))}
                </ul>
              </Card>
            </Grid>
          )}
        </Grid>

        <Box sx={{ mt: 4 }}>
          <Alert severity="success">
            Pour un diagnostic plus approfondi et des conseils personnalisés, 
            n'hésitez pas à prendre rendez-vous pour une consultation.
          </Alert>
        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setActiveStep(0);
            setAnswers({});
            setShowResult(false);
          }}
          sx={{ mt: 3 }}
        >
          Recommencer le Quiz
        </Button>
      </ResultCard>
    );
  };

  return (
    <QuizContainer
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Typography variant="h4" gutterBottom align="center">
        Quiz Postural
      </Typography>
      <Typography variant="body1" paragraph align="center">
        Évaluez vos habitudes posturales et recevez des recommandations personnalisées
      </Typography>

      {!showResult && (
        <>
          <ProgressContainer>
            <LinearProgress
              variant="determinate"
              value={(activeStep / (quizQuestions.length - 1)) * 100}
              sx={{ height: 8, borderRadius: 4 }}
            />
          </ProgressContainer>

          <Stepper activeStep={activeStep} alternativeLabel>
            {quizQuestions.map((_, index) => (
              <Step key={index}>
                <StepLabel>{`Question ${index + 1}`}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {renderQuestion()}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="outlined"
            >
              Précédent
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={answers[activeStep] === undefined}
            >
              {activeStep === quizQuestions.length - 1 ? "Voir les Résultats" : "Suivant"}
            </Button>
          </Box>
        </>
      )}

      {showResult && renderResult()}
    </QuizContainer>
  );
};

export default PostureQuiz;
