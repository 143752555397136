import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Typography,
  Card,
  Button,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Box,
  IconButton,
  LinearProgress,
  Chip
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ReplayIcon from '@mui/icons-material/Replay';
import FavoriteIcon from '@mui/icons-material/Favorite';
import TimerIcon from '@mui/icons-material/Timer';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';

const SimulatorContainer = styled(motion.div)`
  max-width: 1000px;
  margin: 2rem auto;
  padding: 2rem;
`;

const ExerciseCard = styled(Card)`
  padding: 2rem;
  margin: 1rem 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const AnimationContainer = styled(motion.div)`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin: 2rem 0;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 2rem 0;
`;

const StatsContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
  flex-wrap: wrap;
`;

const exercises = [
  {
    id: 1,
    name: "Étirements Cervicaux",
    category: "Cou et Nuque",
    difficulty: "Facile",
    duration: 180,
    steps: [
      "Asseyez-vous confortablement",
      "Inclinez doucement la tête vers la droite",
      "Maintenez 10 secondes",
      "Revenez au centre",
      "Répétez de l'autre côté"
    ],
    benefits: [
      "Soulage les tensions cervicales",
      "Améliore la mobilité du cou",
      "Réduit le stress"
    ],
    precautions: [
      "Évitez les mouvements brusques",
      "Arrêtez si vous ressentez une douleur vive"
    ]
  },
  {
    id: 2,
    name: "Mobilisation Dorsale",
    category: "Dos",
    difficulty: "Modéré",
    duration: 240,
    steps: [
      "Allongez-vous sur le dos",
      "Pliez les genoux",
      "Basculez doucement le bassin",
      "Maintenez 5 secondes",
      "Relâchez et répétez"
    ],
    benefits: [
      "Décompresse la colonne vertébrale",
      "Renforce les muscles profonds",
      "Améliore la posture"
    ],
    precautions: [
      "Gardez les mouvements contrôlés",
      "Respirez régulièrement"
    ]
  },
  {
    id: 3,
    name: "Étirement des Épaules",
    category: "Membres Supérieurs",
    difficulty: "Facile",
    duration: 120,
    steps: [
      "Debout ou assis",
      "Roulez les épaules en arrière",
      "Maintenez la position",
      "Revenez en position neutre",
      "Répétez le mouvement"
    ],
    benefits: [
      "Détend les muscles des épaules",
      "Améliore la circulation",
      "Corrige la posture"
    ],
    precautions: [
      "Mouvements lents et contrôlés",
      "Ne forcez pas l'amplitude"
    ]
  },
  {
    id: 4,
    name: "Étirement des Lombaires",
    category: "Dos",
    difficulty: "Modéré",
    duration: 180,
    steps: [
      "Position quatre pattes",
      "Arrondissez le dos comme un chat",
      "Maintenez 5 secondes",
      "Creusez légèrement le dos",
      "Alternez les positions 10 fois"
    ],
    benefits: [
      "Soulage les douleurs lombaires",
      "Améliore la flexibilité du dos",
      "Renforce les muscles du tronc"
    ],
    precautions: [
      "Protégez votre nuque",
      "Mouvements doux et contrôlés",
      "Respectez vos limites"
    ]
  },
  {
    id: 5,
    name: "Renforcement Abdominal",
    category: "Tronc",
    difficulty: "Avancé",
    duration: 300,
    steps: [
      "Allongez-vous sur le dos",
      "Pliez les genoux, pieds au sol",
      "Engagez les abdominaux",
      "Décollez légèrement les épaules",
      "Maintenez 10 secondes",
      "Reposez et répétez"
    ],
    benefits: [
      "Renforce la sangle abdominale",
      "Améliore la stabilité du tronc",
      "Soutient le dos"
    ],
    precautions: [
      "Ne tirez pas sur la nuque",
      "Respirez régulièrement",
      "Arrêtez si douleur"
    ]
  },
  {
    id: 6,
    name: "Mobilisation des Hanches",
    category: "Membres Inférieurs",
    difficulty: "Facile",
    duration: 240,
    steps: [
      "Debout, appuyé contre un mur",
      "Levez un genou vers la poitrine",
      "Faites des cercles avec la jambe",
      "10 cercles dans chaque sens",
      "Changez de jambe"
    ],
    benefits: [
      "Améliore la mobilité des hanches",
      "Réduit les tensions articulaires",
      "Prévient les douleurs lombaires"
    ],
    precautions: [
      "Gardez le dos droit",
      "Mouvements contrôlés",
      "Évitez les à-coups"
    ]
  },
  {
    id: 7,
    name: "Étirement des Ischio-jambiers",
    category: "Membres Inférieurs",
    difficulty: "Modéré",
    duration: 180,
    steps: [
      "Asseyez-vous au sol",
      "Jambes tendues devant vous",
      "Penchez-vous en avant",
      "Maintenez 15 secondes",
      "Revenez doucement"
    ],
    benefits: [
      "Améliore la souplesse des jambes",
      "Prévient les douleurs lombaires",
      "Favorise la circulation sanguine"
    ],
    precautions: [
      "Ne forcez pas l'étirement",
      "Gardez le dos droit",
      "Respirez profondément"
    ]
  },
  {
    id: 8,
    name: "Rotation du Tronc",
    category: "Tronc",
    difficulty: "Modéré",
    duration: 240,
    steps: [
      "Assis sur une chaise",
      "Dos droit, pieds au sol",
      "Tournez doucement le haut du corps",
      "Maintenez 10 secondes",
      "Alternez les côtés"
    ],
    benefits: [
      "Améliore la mobilité vertébrale",
      "Détend les muscles du dos",
      "Favorise la digestion"
    ],
    precautions: [
      "Rotation progressive",
      "Respectez vos limites",
      "Gardez les hanches fixes"
    ]
  },
  {
    id: 9,
    name: "Équilibre Unipodal",
    category: "Équilibre",
    difficulty: "Avancé",
    duration: 180,
    steps: [
      "Debout près d'un support",
      "Levez une jambe",
      "Maintenez l'équilibre 30 secondes",
      "Fermez les yeux si possible",
      "Changez de jambe"
    ],
    benefits: [
      "Améliore l'équilibre",
      "Renforce les chevilles",
      "Développe la proprioception"
    ],
    precautions: [
      "Restez près d'un support",
      "Ouvrez les yeux si instable",
      "Progression graduelle"
    ]
  }
];

const ExerciseSimulator = () => {
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [completedExercises, setCompletedExercises] = useState([]);

  const handleExerciseSelect = (exercise) => {
    setSelectedExercise(exercise);
    setCurrentStep(0);
    setProgress(0);
    setIsPlaying(false);
  };

  const handlePlay = () => {
    setIsPlaying(!isPlaying);
    if (!isPlaying && progress === 100) {
      setProgress(0);
      setCurrentStep(0);
    }
  };

  const handleReset = () => {
    setProgress(0);
    setCurrentStep(0);
    setIsPlaying(false);
  };

  const handleComplete = () => {
    if (!completedExercises.includes(selectedExercise.id)) {
      setCompletedExercises([...completedExercises, selectedExercise.id]);
    }
    setIsPlaying(false);
    setProgress(100);
  };

  React.useEffect(() => {
    let timer;
    if (isPlaying && selectedExercise) {
      timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            clearInterval(timer);
            setIsPlaying(false);
            return 100;
          }
          const newProgress = oldProgress + (100 / selectedExercise.duration) * 0.1;
          setCurrentStep(Math.floor((newProgress / 100) * selectedExercise.steps.length));
          return Math.min(newProgress, 100);
        });
      }, 100);
    }
    return () => clearInterval(timer);
  }, [isPlaying, selectedExercise]);

  return (
    <SimulatorContainer
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Typography variant="h4" gutterBottom align="center">
        Simulateur d'Exercices
      </Typography>
      <Typography variant="body1" paragraph align="center">
        Sélectionnez un exercice et suivez les instructions pas à pas
      </Typography>

      {!selectedExercise ? (
        <Grid container spacing={3}>
          {exercises.map((exercise) => (
            <Grid item xs={12} md={6} lg={4} key={exercise.id}>
              <ExerciseCard
                onClick={() => handleExerciseSelect(exercise)}
                component={motion.div}
                whileHover={{ scale: 1.02 }}
              >
                <Typography variant="h6" gutterBottom>
                  {exercise.name}
                </Typography>
                <StatsContainer>
                  <Chip
                    icon={<FitnessCenterIcon />}
                    label={exercise.difficulty}
                    color="primary"
                    variant="outlined"
                  />
                  <Chip
                    icon={<TimerIcon />}
                    label={`${exercise.duration / 60}min`}
                    color="secondary"
                    variant="outlined"
                  />
                  {completedExercises.includes(exercise.id) && (
                    <Chip
                      icon={<FavoriteIcon />}
                      label="Complété"
                      color="success"
                    />
                  )}
                </StatsContainer>
                <Typography variant="body2" color="textSecondary">
                  {exercise.category}
                </Typography>
              </ExerciseCard>
            </Grid>
          ))}
        </Grid>
      ) : (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <Button
            variant="outlined"
            onClick={() => setSelectedExercise(null)}
            sx={{ mb: 3 }}
          >
            Retour aux exercices
          </Button>

          <Typography variant="h5" gutterBottom>
            {selectedExercise.name}
          </Typography>

          <StatsContainer>
            <Chip
              icon={<FitnessCenterIcon />}
              label={selectedExercise.difficulty}
              color="primary"
            />
            <Chip
              icon={<TimerIcon />}
              label={`${selectedExercise.duration / 60}min`}
              color="secondary"
            />
          </StatsContainer>

          <AnimationContainer>
            <Typography variant="h6" align="center">
              {selectedExercise.steps[currentStep]}
            </Typography>
          </AnimationContainer>

          <Box sx={{ width: '100%', mb: 2 }}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>

          <Stepper activeStep={currentStep} alternativeLabel>
            {selectedExercise.steps.map((step, index) => (
              <Step key={index}>
                <StepLabel>{`Étape ${index + 1}`}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <ControlsContainer>
            <IconButton
              color="primary"
              onClick={handlePlay}
              size="large"
            >
              {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
            <IconButton
              color="secondary"
              onClick={handleReset}
              size="large"
            >
              <ReplayIcon />
            </IconButton>
          </ControlsContainer>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Bénéfices
                </Typography>
                <ul>
                  {selectedExercise.benefits.map((benefit, index) => (
                    <li key={index}>
                      <Typography variant="body2">{benefit}</Typography>
                    </li>
                  ))}
                </ul>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Précautions
                </Typography>
                <ul>
                  {selectedExercise.precautions.map((precaution, index) => (
                    <li key={index}>
                      <Typography variant="body2">{precaution}</Typography>
                    </li>
                  ))}
                </ul>
              </Card>
            </Grid>
          </Grid>
        </motion.div>
      )}
    </SimulatorContainer>
  );
};

export default ExerciseSimulator;
